<template>
  <div v-if="myMBTI && !showAllTypes" class="container cover">
    <h1 class="result-title" v-html="result[myMBTI].title"></h1>
    <img :src="`/result/${myMBTI.toUpperCase()}.png`" :alt="result[myMBTI].title" class="result" />
    <section class="bubble">{{result[myMBTI].description}}</section>
    <section class="quote">
      <h4>명언을 한다라면 ...</h4>
      <div class="quote-page">
        <div class="handle"></div>
        <div class="paper" v-html="result[myMBTI].quote"></div>
        <div class="handle"></div>
      </div>
    </section>
    <section v-if="myGoodPartner" class="partner">
      <h2 class="sub-title">환상의 궁합은?</h2>
      <h3 class="partner-title" v-html="result[myGoodPartner].partnerTitle"></h3>
      <div class="bubble-left">
        <img
          :src="`/result/${myGoodPartner.toUpperCase()}.png`"
          :alt="result[myGoodPartner].partnerTitle"
          class="result-partner"
        />
      </div>
    </section>
    <section v-if="myBadPartner" class="partner">
      <h2 class="sub-title">환장의 궁합은?</h2>
      <h3 class="partner-title" v-html="result[myBadPartner].partnerTitle"></h3>
      <div class="bubble-left orange">
        <img
          :src="`/result/${myBadPartner.toUpperCase()}.png`"
          :alt="result[myBadPartner].partnerTitle"
          class="result-partner"
        />
      </div>
    </section>
    <section v-if="myBadPartner" class="partner">
      <h2 class="sub-title" :style="{'width': '160px'}">너도 해봐! 공유하기</h2>
      <h3 class="count">지금까지 {{numberWithCommas(count)}}명이 참여했어요!</h3>
      <div class="share-box">
        <a
          class="icon"
          target="_blank"
          onclick="window.open(this.href,'targetWindow',`toolbar=no, location=no, status=no, menubar=no, scrollbars=no, resizable=no, width=600px, height=800px `);return false;"
          :href="`https://www.facebook.com/sharer/sharer.php?u=https://rbti-ns.com/result/${myMBTI}`"
        >
          <picture>
            <img src="/icon/facebook.png" alt="페이스북 공유하기" />
          </picture>
        </a>
        <a
          class="icon"
          target="_blank"
          onclick="window.open(this.href,'targetWindow',`toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=600px,height=800px`); return false;"
          :href="`https://twitter.com/intent/tweet?url=https://rbti-ns.com/result/${myMBTI}`"
        >
          <picture>
            <img src="/icon/twitter.png" alt="트위터 공유하기" :style="{'border-radius' : '0px'}" />
          </picture>
        </a>
        <a class="icon" @click="kakao" id="kakao-link-btn">
          <picture>
            <img src="/icon/kakao.png" alt="카카오톡 공유하기" />
          </picture>
        </a>
        <a class="icon" @click.prevent="copyURL">
          <picture>
            <img src="/icon/link.png" alt="링크 공유하기" />
          </picture>
        </a>
      </div>
    </section>
    <button class="result" @click="() => {showAllTypes = true}">모든 유형 보러가기</button>
    <button class="result" @click="moveToRecipe">농심 라면 꿀조합 레시피 보러가기</button>
    <button class="result" @click="goHome">테스트 다시하기</button>
  </div>
  <div v-else-if="showAllTypes" class="container cover">
    <h1 style="text-align:center;">
      '내가
      <span class="large">라면</span>이
      <span class="large">라면?</span>'
    </h1>
    <section class="quote">
      <div class="quote-page">
        <div class="handle"></div>
        <div class="paper">모든 유형 알아보기</div>
        <div class="handle"></div>
      </div>
    </section>
    <div class="type-container">
      <button
        v-for="type in Object.keys(result)"
        :key="type"
        class="type-btn"
        @click="goResult(type)"
      >
        <div class="type-text" v-html="result[type].title"></div>
        <picture>
          <img :src="`/result/${type.toUpperCase()}.png`" :alt="result[type].title" />
        </picture>
      </button>
    </div>
    <button class="result" @click="goHome">테스트 다시하기</button>
    <button class="result" @click="() => {showAllTypes = false}">결과로 돌아가기</button>
  </div>
</template>
<script>
import copy from "copy-to-clipboard";
export default {
  name: "Result",
  data() {
    return {
      showAllTypes: false,
      myMBTI: null,
      count: 0,
      result: {
        entj: {
          title: "내가 왕이 될 상인가<br/><span class='title'>‘짜왕’</span>",
          shareTitle: "내가 왕이 될 상인가 ‘짜왕’",
          partnerTitle:
            "내가 왕이 될 상인가<br/><span class='title'>‘짜왕’</span>",
          description: `본인이 직접 모임을 주도하고
            적극적으로 대화를 이끌어 나가는 
            힘이 있는 성격이에요.

            내가 하는 일들은 다 잘돼야 하고, 
            리더로서 인정받길 원해요.

            성질이 급해 여유로운 애랑 팀플하면 답답해 하고 
            결국 총대 메고 본인이 다 해버려요.

            약간 관종끼가 있어서 
            새로운 사람 만나는 걸 좋아해도 
            깊은 관계는 원하지 않아요.

            사람 많을 땐 현실적이지만,
            혼자있으면 예측 불가능한 
            상상을 하기도 해요.

            일,사랑 둘다 포기 못 하는 사람들이 많아요. 
            감정 표현이 솔직해서 타인에게 
            상처를 잘 주는 타입이에요.

            모임 중에 분위기 잘못 타서 갑분싸 자주 만들어요.`,
          good: "estp",
          bad: "istp",
          quote: "당장 물 올려!<br/>왜? 12시에 칼로리 리셋되니까",
          link: "https://www.youtube.com/watch?v=8ZiZi27qF3c"
        },
        infp: {
          title:
            "누구에게나 안성맞춤<br/><span class='title'>‘안성탕면’</span>",
          shareTitle: "누구에게나 안성맞춤 '안성탕면'",
          partnerTitle:
            "누구에게나 안성맞춤<br/><span class='title'>‘안성탕면’</span>",
          description: `안성탕면 같은 당신은 
            누구에게나 잘 맞추는 성격이에요.

            동시에 세심한 성격의 소유자로 
            작은 것에도 상처를 잘 받아요.

            부끄러움을 잘 타지만 친해지면 
            장난도 치고 조곤조곤 할 말 다 해요.

            공감 능력이 좋아 다른 사람의 얘기를 
            들어줄 때 리액션을 잘 해줘요. 
            하지만 속으로는 잡생각도 많은 건 비밀이에요.

            관심받는 것을 싫어하는 것 같지만 
            은근히 즐기고 있어요.

            무언가를 할 땐 계획적이거나 빠르진 않아 
            다른 길로 새기도 하지만, 
            완벽주의 성향이 있어 결국 잘 해내요.

            청양고추같은 매운 존재와 만나면 
            내면의 감추고 있던 열정을 뿜어내는 성격이에요.`,
          good: "istj",
          bad: "esfj",
          quote:
            "원래 알던 라면은 아니까 먹고<br/>새로 나온 라면은 궁금해서 먹는다.",
          link: "https://www.youtube.com/watch?v=vW3biawxKOQ"
        },
        enfj: {
          title:
            "너굴맨이 등장했으니 안심하라구!<br/><span class='title'>‘너구리’</span>",
          shareTitle: "너굴맨이 등장했으니 안심하라구! '너구리'",
          partnerTitle:
            "너굴맨이 등장했으니<br/>안심하라구!<br/><span class='title'>‘너구리’</span>",
          description: `너구리처럼 수용력이 넘치고
            항상 상대방을 생각하며 감수성도 충만해요.

            적극적이고 추진력이 강해요. 
            남을 생각해서라도 맡은 일은 
            열심히하고 결과물도 좋은 경우가 많아요.

            걱정이 매우 많은 성격이지만 
            남들한테 티를 잘 내지 않아요. 
            하지만 얼굴에 다 적혀있는 경우가 많아요.

            상처를 매우 잘 받고 
            오랜 시간 가슴 아파하니 배신하지 말아요. 
            배신 당하면 정신적인 타격이 클거에요.

            상대방의 말, 특히 비판에 굉장히 민감해요.

            갈등을 싫어해요. 
            모두가 그냥 서로 존중하고 사랑했으면 좋겠어요. 
            
            오지랖이 넓은 편이에요.

            사람을 좋아하고 좋아하는 사람들에게는 
            한없이 배푸는 스타일이에요.`,
          good: "enfp",
          bad: "infj",
          quote:
            "새벽 2시 라면이 진리인 것처럼<br/>당신을 사랑합니다. -너굴 애덤스-",
          link: "https://www.youtube.com/watch?v=_gWIjLEUydQ"
        },
        istj: {
          title:
            "한국인의 맛?<br/>우리는 이걸 육개장이라고 부르기로 했어요.<br/><span class='title'>‘육개장’</span>",
          shareTitle:
            "한국인의 맛? 우리는 이걸 육개장이라고 부르기로 했어요. '육개장'",
          partnerTitle:
            "한국인의 맛?<br/>우리는 이걸 육개장이라고<br/>부르기로 했어요.<br/><span class='title'>‘육개장’</span>",
          description: `라면계의 근본으로 불리는 육개장처럼
            당신의 논리적인 선택은 
            대부분 정답일 확률이 높아요.
            
            소크라테스가 가장 좋아해요. 
            ‘네 주제를 잘 알기 때문이죠’ 
            
            철저하고 계획적이며 규칙을 잘 지켜요.

            하지 말라면 다 이유가 있는 건데,
            꼭 못 참고 해보는 답답한 캐릭터를 보면 
            꿀밤을 때리고 싶어요.

            내가 보낸 카톡은 수십 개 쌓여있지만,
            다른 친구와는 연락하는 안읽씹빌런은 못 참아요.

            선비 같다는 이야기를 많이 듣지만 
            본인만의 선이기에 그게 싫으면 
            나를 안 만나면 돼요.

            위계질서, 체계, 규칙을 가장 좋아하는 유형으로 
            톱니바퀴가 딱인 한국인에게 최적화됐어요.`,
          good: "esfj",
          bad: "estp",
          quote: "라면의 칼로리는 열량이 아닌<br/> 맛을 뜻하는 단위다.",
          link: "https://www.youtube.com/watch?v=dqR8-7X6fKU"
        },
        isfj: {
          title:
            "라면인건가 우동인건가 oh<br/><span class='title'>‘튀김우동’</span>",
          shareTitle: "라면인건가 우동인건가 oh '튀김우동'",
          partnerTitle:
            "라면인건가<br/> 우동인건가 oh<br/><span class='title'>‘튀김우동’</span>",
          description: `라면인지 우동인지 헷갈리는 튀김우동처럼
            내향적인 거 같으면서도 외향적이어서 때로는 정체성에 혼란이 와요.

            내 생각과 주장은 이런데 차마 입 밖으로는 
            나오지 않아서 알아서 생각한 대로 해줬으면 해요.

            좋은 게 좋은 거라는 마인드가 박혀 있어요.

            기본적으로 온화한 우동 같으나 싫어하고 
            선 넘는 친구가 있다면 매운맛이 될 수도..?

            남이 볼 때는 인생 노잼이지만 나는 매우 만족해요.

            파멸적이고 스펙타클한 인생보다는 
            굵고 긴 삶이 좋아요. 
            파도 많은 삶은 피곤하니깐~

            남에게 맞춰주는 거 같으면서도 
            은근히 고집이 있어서 타인들이 하여금 
            긴가민가한 상황을 종종 만들어요.`,
          good: "intp",
          bad: "entp",
          quote: "라면에 물이 많으면<br/>라면을 더 넣으면 된다.",
          link: "https://www.instagram.com/p/CRnEqCppNZs/?utm_medium=copy_link"
        },
        entp: {
          title:
            "넌 내일도 사냐? 난 오늘만 산다<br/><span class='title'>‘앵그리 짜파구리’</span>",
          shareTitle: "넌 내일도 사냐? 난 오늘만 산다 '앵그리 짜파구리'",
          partnerTitle:
            "넌 내일도 사냐?<br/>난 오늘만 산다<br/><span class='title'>‘앵그리 짜파구리’</span>",
          description: `두 제품을 섞은 짜파구리처럼 다재다능한 
            팔방미인형이에요. 
            
            무엇을 하든 간에 최소 평균 이상은 하는 편이에요.
            
            ‘나 시험공부 별로 안 했어‘라고 얘기하는데 진짜예요. 
            벼락치기하는 경우가 많아요. 
            근데 노력 대비 결과가 잘 나와요.
            
            인싸의 특성도 있지만 인간관계가 피곤해서 
            자발적 아싸의 ‘혼자서도 잘해요‘ 마인드가 공존해요.
            
            한 시간이면 할 일을 일주일에 걸쳐서 하고, 
            일주일 걸릴 일은 한 시간만에 해내는 유형이에요.

            관리를 할 때와 안 할 때의 갭차이가 
            가장 큰 유형이에요. 
            
            자기애가 강한 만큼 자기합리화를 자주해요.

            나한테 잘해주면 끝도 없이 잘해주지만 
            나한테 못하면 국물도 없어요.`,
          good: "estj",
          bad: "enfj",
          quote: "물을 많이 마시려면 국물까지 마셔라.",
          link: "https://www.youtube.com/watch?v=NktXpE8KL64"
        },
        enfp: {
          title:
            "화끈하고 모두에게 사랑받는 사랑둥이!<br/><span class='title'>‘신라면’</span>",
          shareTitle: "화끈하고 모두에게 사랑받는 사랑둥이! '신라면'",
          partnerTitle:
            "화끈하고 모두에게 사랑받는 사랑둥이!<br/><span class='title'>‘신라면’</span>",
          description: `신라면처럼 모두에게 사랑받는 경우가 많아요. 
          자리에서 사람들을 기쁘게 해주는 
          타고난 능력의 소유자랍니다.

          양보를 잘해줘요. 본인이 아쉬운게 아니라면 
          흔쾌하게 양보하는 쿨내가 풀풀나는 타입이에요.

          평소에 화를 잘 안 내요. 근데 토라지는 건 잘해요. 
          하지만 달래주면 금방 풀리는 스타일이에요.

          잘 놀다가도 몰입이 안되고,
          ‘지금 뭘하고 있지?’ 하는 생각이 들 때가 많아요.

          그만큼 쉽게 열중했다가 쉽게 그만둬요. 

          상처를 받으면 무덤덤해 보여도 
          속으론 꽤나 슬퍼해요

          이 타입 사람들이 
          ‘나 다이어트해‘ 라고 말한다면 믿지 마세요. 
          다음날 치킨이 인스타 스토리에 
          올라올 확률이 높아요.`,
          good: "isfp",
          bad: "isfj",
          quote: "낮이밤저, <br/>낮에는 이거 먹고 밤에는 저거 먹고",
          link: "https://www.youtube.com/watch?v=wzcAXP689oY"
        },
        intj: {
          title:
            "튀기지 않아 깔끔한 나, 제법 매콤해요<br/><span class='title'>‘신라면 건면’</span>",
          shareTitle: "튀기지 않아 깔끔한 나, 제법 매콤해요 '신라면 건면'",
          partnerTitle:
            "튀기지 않아 깔끔한 나, 제법 매콤해요<br/><span class='title'>‘신라면 건면’</span>",
          description: `신라면 건면 같은 당신은 거침없는 자신감이 있고,
          자기관리가 철저해요.

          독립심이 강하고 매사에 
          자신이 있어서 잘 휘둘리지 않아요. 
          그래서인지 관심 밖의 일에는 
          신경을 거의 쓰지 않아요.

          연애나 가족보다 본인이 우선인 경향이 있어요. 
          타인이 보기에는 차갑고 다가가기 
          어려운 존재일 수 있어요.

          효율을 중시하고 혼자 무언가 해내는 것을 좋아해요. 
          무언가를 다양한 관점으로 분석하는 것을 즐겨요.

          거짓말을 굉장히 싫어하고, 
          잘못됐다고 생각하는 것은 짚고 넘어가야 해요.

          눈에 보이지 않는 것을 믿는 일이 잘 없어요. 
          공감 능력이 좋지 않아 리액션을 잘 못해요.`,
          good: "isfj",
          bad: "isfp",
          quote: "라면 먹고 운동하면 돼요.<br/>숨쉬기 운동.",
          link: "https://www.youtube.com/watch?v=BvTHBcD4KfI"
        },
        isfp: {
          title:
            "포용력이 좋은 너와 놀고시포용<br/><span class='title'>‘짜파게티’</span>",
          shareTitle: "포용력이 좋은 너와 놀고시포용 '짜파게티'",
          partnerTitle:
            "포용력이 좋은<br/>너와 놀고시포용<br/><span class='title'>‘짜파게티’</span>",
          description: `개구쟁이 같은 짜파게티처럼 
            호기심이 많은 성격이에요.

            도전을 두려워하지 않고 때로는
            ‘대체 무슨 생각을 하는 지 모르겠다’
            라는 말을 들어요.

            다양한 것을 소화할 줄 알며,
            어떤 것을 넣어도 조화가 되는 
            융통성이 있는 사람이에요.
            
            낙관적이고 유한 성격으로 
            누구를 만나도 친하게 지내는 편이에요.
            
            사람 만나는 건 좋은데 나가기 귀찮아요. 
            근데 나가면 잘 놀아요.
            
            짜파게티처럼 부드럽고 순한 매력으로 
            호불호가 없는 편이지만, 
            라면처럼 생각보다 질긴 고집과 
            말보다는 행동이 앞서는 편이에요.

            내유외강이라는 표현이 적절해요. 
            쉽게 상처받는 여린 마음 때문에 
            자기방어를 강하게 하는 편이에요.`,
          good: "istp",
          bad: "estj",
          quote: "라면 먹고 바로 누우면 안 되니까<br/>누워서 먹자.",
          link: "https://www.youtube.com/watch?v=xLBB1uNuyoM"
        },
        intp: {
          title:
            "알면 알수록 진국이여<br/><span class='title'>‘사리곰탕’</span>",
          shareTitle: "알면 알수록 진국이여 '사리곰탕'",
          partnerTitle:
            "알면 알수록 진국이여<br/><span class='title'>‘사리곰탕’</span>",
          description: `사리곰탕 같은 당신은 알면 알수록 
            진국인 사람이에요.

            주관이 뚜렷하고 창의적이에요.

            자기애가 강해요. 세상에서 내가 가장 작고 소중해요.

            추리물, 지식 프로그램 같은 것을 보고 
            생각을 많이 해요. 
            
            논리와 지식을 좋아해서 
            논리적이지 못한 것을 피해요.

            다양한 아이디어를 갖고 있지만 
            표현하는 경우는 드물어요. 
            나중에 ‘그럴 줄 알았어!’라며 
            뒷북을 치기도 한답니다.

            스스로에게 관심이 많고 
            자기 분석을 좋아해요.
            그에 비해 타인에게는 관심이 별로 없고 
            상처도 잘 안 받아요.

            영혼 없는 리액션으로 
            사람들에게 무뚝뚝하다는 말을 들어요. 
            
            대화를 즐기진 않지만 
            관심 있는 분야가 나오면 말이 많아져요.`,
          good: "entp",
          bad: "infp",
          quote: "두 봉지도 혼자 먹으면 그게 1인분이다.",
          link: "https://www.youtube.com/watch?v=Ljkk2gOunJE"
        },
        estj: {
          title: " 거 참 속 시원하다~<br/><span class='title'>‘무파마’</span>",
          shareTitle: "거 참 속 시원하다~ '무파마'",
          partnerTitle:
            " 거 참 속 시원하다~<br/><span class='title'>‘무파마’</span>",
          description: `속이 시원~해지는 재료들이 다 모여있는 무파마처럼 
          뒤끝없이 시원한 성격을 가지고있어요.

          바글바글 사람 많은 곳은 질색!!
          그래서 사람들 앞에서랑 
          혼자 있을 때 성격이 조금 달라요.

          노는 것보다 배우는 걸 
          좋아하는 만큼 일에 열정이 있어서 
          답답한 일처리 질색팔색! 신속한 일처리 부탁해요.

          계획이 틀어지면 스트레스 폭발함. 
          즉흥으로 무언가를 하는 것은 절대 이해할 수 없어요.

          리더 맡기를 싫어하지만 
          막상 하면 최고의 리더쉽을 보여줘요. 
          후보가 없다면 이 사람을 추천해야 해요!

          나의 시간을 방해하는 건 용서 못해!
          100가지 이유를 갖고오지 않는 이상
          마이웨이 하는 성격이에요.`,
          good: "infp",
          bad: "esfp",
          quote: "꼬들, 퍼짐 고민할 시간에<br/>한봉이라도 더 먹어라.",
          link: "https://www.youtube.com/watch?v=U6ogv0zqhG4"
        },
        istp: {
          title:
            " 너 은근히 나랑 잘 맞을지도~?<br/><span class='title'>‘오징어짬뽕’</span>",
          shareTitle: "너 은근히 나랑 잘 맞을지도~? '오징어짬뽕'",
          partnerTitle:
            " 너 은근히<br/> 나랑 잘 맞을지도~?<br/><span class='title'>‘오징어짬뽕’</span>",
          description: `도도하고 때로는 차갑지만 같은 오짬파를 만나면 
          끓어오르는 소속감을 느껴요.

          오짬을 좋아한다고 하면 혹시나 다른 취향은 같은지, 
          통하는 게 있는지 한없이 다가가요.

          취향이 확고하여 덕질에 최적화되어있어요.

          수직적이고 불필요한 규칙과 위계질서는 질색이에요. 
          그렇지만 미루는 것을 좋아해요.

          사자에게 쫓겨야 최대 집중력을 발휘하는 
          사슴과 같은 인생을 살아요.

          혼자만의 생활을 즐기는 마이웨이 스타일이에요. 
          뭐라고 하지 말아요. 제 인생이랍니다.

          고민 상담을 해줄 때 현실적인 판단을 우선시해요.`,
          good: "enfj",
          bad: "intj",
          quote: "라면은 중력인가? 계속 땡긴다.",
          link: "https://www.youtube.com/watch?v=cExPc1cg9qI"
        },
        esfj: {
          title:
            "뭘 넣어도 맛있갱~ 부찌갱~<br/><span class='title'>‘보글보글 부대찌개면’</span>",
          shareTitle: "뭘 넣어도 맛있갱~ 부찌갱~ '보글보글 부대찌개면'",
          partnerTitle:
            "뭘 넣어도<br/> 맛있갱~ 부찌갱~<br/><span class='title'>‘보글보글 부대찌개면’</span>",
          description: `부찌면처럼 사교적이고 섬세해서
          주변사람을 빠짐없이 챙기고 
          이 사람들이 불행하면 
          내가 다 불행해지는 수준이에요.

          인간관계 틀어지면 그것만큼 
          스트레스 받는 일이 없어요. 

          생각보다 철저하게 계획을 세우며, 
          계획이 틀어지는 것이 싫어요. 

          술자리를 좋아하는데 특히 새로운 
          사람과의 자리는 금상첨화예요. 

          이타적이어서 잘 맞춰주는 편이라 
          남 눈치를 많이 봐요. 

          서비스 정신이 투철해서 
          일 잘한다는 얘기를 많이 들어요. 

          취미가 다양한데 특히 책 읽고 
          영화 보는 것을 좋아해요.

          인간관계에서 상처받아도 
          그 사람 배려한다고 얘기 하지 못해요. 
          
          어디 나가면 어색한 거 못 참고 
          먼저 말을 거는 편이에요.`,
          good: "infj",
          bad: "intp",
          quote: "첫 입은 설레고 마지막 입은 그리운 것은 -라면-",
          link: "https://www.youtube.com/watch?v=Z8zM8D4Y7p4"
        },
        esfp: {
          title:
            "매콤새콤 톡톡튀는 라이징 스타<br/><span class='title'>‘배홍동 비빔면’</span>",
          shareTitle: "매콤새콤 톡톡튀는 라이징 스타 '배흥동 비빔면'",
          partnerTitle:
            "매콤새콤 톡톡튀는<br/>라이징 스타<br/><span class='title'>‘배홍동 비빔면’</span>",
          description: `배홍동의 맛처럼 
          톡톡튀는 성격으로 전형적인 인싸에요. 
          성격이 겁나 급해요. 
          우주최강오지랖이어서 정이 많아요.

          혼자 있으면 지루해서 
          뭔가를 계속 하고 있어야 직성이 풀려요.

          사람의 단점보단 장점을 보려하고 
          싸움보단 평화가 좋아요.

          낙천적이고, 아무리 걱정되는 일이 있어도 
          조금만 시간이 지나면 별 생각이 안 들어요.

          하고 싶다고 생각한 거 다 해야 되지만, 
          하기 싫은 건 끝까지 미루는 편이에요.

          모임장소에서 침묵이 흐르는 꼴을 못봐요.

          메세지 안읽씹을 오래하나, 안 오는 건 싫어요.

          사람이 너무 좋고 어울리는 것도 진짜 좋은데 
          막상 집 밖에 나가는 것은 귀찮아요.`,
          good: "intj",
          bad: "istj",
          quote: "몇 개월이야? 아니 이거 라면 5봉지야.",
          link: "https://www.youtube.com/watch?v=gJeUBaFdgdc"
        },
        estp: {
          title:
            "열정 열정 열정!<br/><span class='title'>‘신라면 볶음면’</span>",
          shareTitle: "열정 열정 열정! '신라면 볶음면'",
          partnerTitle:
            "열정 열정 열정!<br/><span class='title'>‘신라면 볶음면’</span>",
          description: `신볶면의 화끈함처럼 
            관종의 끼가 내재되어 있고
            어느 부분에서든, 항상 표현을 아끼지 않아요.

            자유를 추구하고 낙천적이나 
            혼자 있으면 외로움을 많이 느껴요. 

            리더쉽이 있어 조별 활동에서 
            조장 혹은 반장을 도맡아 해요.

            밖에서 사람 만나는 걸 좋아하지만 
            게을러서 외출까지 시간이 오래 걸리고 귀찮아 해요.

            하고 싶은 거 다 해야 하지만,
            못하면 혼자서 스트레스 받다가 곧 까먹어요.

            태어났으니까 사는 느낌이 있어요. 
            대충 살고 눈치도 잘 안봐요.

            남한테 그다지 관심이 없고 생각하는 것도 귀찮아서 
            공감능력이 조금 부족한 편이에요.`,
          good: "entj",
          bad: "enfp",
          quote: "먹겠다는 의지가 있으면 두 봉지도 먹을 수 있다.",
          link: "https://www.youtube.com/watch?v=JnVDY1IC6Ck"
        },
        infj: {
          title:
            "거친 겉면에 그렇지 못한 따스함<br/><span class='title'>‘감자면’</span>",
          shareTitle: "거친 겉면에 그렇지 못한 따스함 '감자면'",
          partnerTitle:
            "거친 겉면에<br/>그렇지 못한 따스함<br/><span class='title'>‘감자면’</span>",
          description: `감자면 같은 당신은 어른스럽고
            따뜻한 마음을 가졌어요. 

            친해지기 전까지는 속을 잘 드러내지 않지만
            한 번 가까워지면 쫄깃한 우정(의리)을 보여줘요.

            하지만 선을 넘으면 미련 없이 
            관계를 정리하는 차가운 면도 있답니다.

            사교성이 있긴 하지만 혼자 있는 시간을 소중히 해요.

            주로 생각을 표현할 때 돌려 말하는
            경향이 있어 전달이 잘못될 때도 있어요.
            
            출처가 불분명한 근거 없는 소문은 믿지 않아요.

            어른스럽다는 말을 듣기도 하며, 
            조용하지만 가끔 튀고 싶어 해요. 
            도덕적인 편이고 어려운 사람들을 
            돕는 것을 좋아해요.

            다른 사람에게 상처를 잘 받지만 티는 내지 않아요. 
            구속받는 것을 싫어해서 
            수평적인 분위기에서 적응을 잘 해요.`,
          good: "esfp",
          bad: "entj",
          quote:
            "라면을 멀리하면 살을 뺄 수 있지만<br/>그렇다면 딱히 살을 뺄 이유가 없다",
          link: "https://www.youtube.com/watch?v=oVyUDUjax1I"
        }
      }
    };
  },
  metaInfo() {
    return {
      title: this.result[this.myMBTI].shareTitle,
      description: this.result[this.myMBTI].description,
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content: this.result[this.myMBTI].shareTitle
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: `https://rbti-ns.com/result/${this.myMBTI.toUpperCase()}.png`
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.result[this.myMBTI].description,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `https://rbti-ns.com/result/${this.myMBTI}`,
        }
      ]
    };
  },
  computed: {
    myGoodPartner() {
      return this.result[this.myMBTI].good;
    },
    myBadPartner() {
      return this.result[this.myMBTI].bad;
    }
  },
  created() {
    this.myMBTI = this.$route.params.id;
    if (this.result[this.myMBTI] === undefined) {
      this.$router.push({
        name: "Home"
      });
    }
  },
  mounted() {
    document.body.style.backgroundColor = "#ffffff";
    const docRef = this.$firestore.collection("user").doc("rbti");
    docRef.get().then(doc => {
      const { finishCount } = doc.data();
      this.count = finishCount;
    });
  },
  methods: {
    goHome() {
      this.$router.push({
        name: "Home"
      });
    },
    goResult(type) {
      this.$router.push({
        name: "Result",
        params: { id: type }
      });
      this.showAllTypes = false;
      this.myMBTI = type;
      window.scrollTo(0, 0);
    },
    moveToRecipe() {
      window.location.href = this.result[this.myMBTI].link;
    },
    kakao() {
      Kakao.Link.sendDefault({
        objectType: "feed",
        content: {
          title: this.result[this.myMBTI].shareTitle,
          description: this.result[this.myMBTI].description,
          imageUrl: `https://rbti-ns.com/result/${this.myMBTI.toUpperCase()}.png`,
          link: {
            mobileWebUrl: `https://rbti-ns.com/result/${this.myMBTI}`,
            webUrl: `https://rbti-ns.com/result/${this.myMBTI}`
          }
        },
        buttons: [
          {
            title: "테스트 시작!",
            link: {
              mobileWebUrl: `https://rbti-ns.com/result/${this.myMBTI}`,
              webUrl: `https://rbti-ns.com/result/${this.myMBTI}`
            }
          }
        ]
      });
    },
    copyURL() {
      copy(`https://rbti-ns.com/result/${this.myMBTI}`);
      window.alert("주소를 복사했습니다.");
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
};
</script>
<style lang="scss">
section.quote {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 30px;
  width: 100%;
  max-width: 320px;
  & > h4 {
    font-family: "ChosunCentennial";
  }
  .handle {
    background-color: #83453a;
    height: 60px;
    width: 10px;
    border: 2px solid black;
    &::before,
    &::after {
      content: "";
      display: block;
      position: relative;
      height: 4px;
      background-color: #83453a;
      border: 2px solid black;
    }
    &::before {
      width: 4px;
      top: -9px;
      left: -2px;
    }
    &::after {
      top: 47px;
      width: 4px;
    }
    &:last-child {
      position: relative;
      top: -1px;
      transform: scale(1, -1);
    }
  }
  .quote-page {
    display: flex;
    width: 100%;
  }
  .paper {
    font-family: "ChosunCentennial";
    background-color: #fdedd3;
    border: 2px solid black;
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 60px;
    align-items: center;
    text-align: center;
  }
}
.type-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 30px;
  .type-btn {
    text-decoration: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    width: calc(50% - 5px);
    flex-direction: column;
    border: 5px solid #ffcbb2;
    border-radius: 20px;
    padding: 10px;
    background-color: white;
    img {
      width: 100px;
      height: 100px;
    }
    .type-text {
      color: black;
      font-weight: bold;
      margin-bottom: 10px;
      letter-spacing: -1.5px;
      word-break: keep-all;
      & > .title {
        color: #ff4c48;
      }
    }
  }
}
</style>